import React from 'react'
import '../../components/styles/global.scss'
import { Link } from 'gatsby'
import SegmentedControl from '../../components/common/SegmentedControl'
import Layout from '../../components/common/Layout'
import { StaticImage } from 'gatsby-plugin-image'

export default function Pipeline() {
    return (
        <Layout active='pipeline'
        title='PharmaEssentia Medical Affairs | Our Pipeline'
        description='The global clinical pipeline reflects a hematology/oncology focus with candidates across a range of indications in development.'
        socialTitle='PharmaEssentia Medical Affairs | Our Pipeline'
        socialDescription='The global clinical pipeline reflects a hematology/oncology focus with candidates across a range of indications in development.'
        pathname='/pipeline'
        >
            <section>
                <div className='inner'>
                    <h1>Our Pipeline</h1>
                    <SegmentedControl buttons='our-pipeline' active='pipeline'/>
                    <h3>Pipeline Overview</h3>
                    <p>The global clinical pipeline reflects a hematology/oncology focus with candidates across a range of indications in development. Through innovative science, we intend to expand into other areas where our technology can improve outcomes and address the unmet medical needs in myeloproliferative neoplasms.</p>
                    <span id='wrapper-chart'>
                        {/*
                            initially placed this in the static folder - wasnt loading from raster for some reason
                            <img src='pipeline.webp' alt='PharmaEssentia global clinical pipeline'/>

                            //  issue seems to perhaps be webp format
                            */
                        }
                        <StaticImage
                        src='../../raster/pipeline.png'
                        alt='PharmaEssentia global clinical pipeline'
                        placeholder='none'
                        />
                    </span>
                    <Link to='/resources/medical-information' className='cta'>
                        To learn more about any of our pipeline products, <span className='bold brand-secondary'>contact a medical science liaison <span className='triangle'></span></span>
                    </Link>
                </div>
            </section>
        </Layout>
    )
}